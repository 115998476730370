import React, { useState, useEffect } from 'react';
import { Mail, Linkedin, Github, ExternalLink, Award, ChevronDown, ChevronUp, Filter } from 'lucide-react';

const projects = [
  { name: 'BERT Semantic Interlinker', description: 'Find related pages to interlink automatically', link: 'https://bertlinker.streamlit.app/', type: 'Streamlit', category: 'Linking', color: 'purple' },
  { name: 'Simple GSC Downloader', description: 'Login and download your search console data!', link: 'https://gscdata.streamlit.app', type: 'Streamlit', category: 'Migration', color: 'blue' },
  { name: 'Wikipedia Citation Finder', description: 'Find citation opportunities with Wikipedia', link: 'https://wayback.streamlit.app/', type: 'Streamlit', category: 'Linking', color: 'green' },
  { name: 'Semantic Keyword Clustering', description: 'Semantically Cluster keywords using Sentence Transformers', link: 'https://github.com/searchsolved/search-solved-public-seo/tree/main/keyword-clustering/semantic-clustering/semantic-clustering-cli-app', type: 'Command Line', category: 'Clustering', color: 'orange' },
  { name: 'Automatic Category Suggester', description: 'Merges two crawl files from Screaming Frog to suggest new landing pages to align inventory to search demand', link: 'https://github.com/searchsolved/search-solved-public-seo/tree/main/ecommerce/automatic-category-suggester', type: 'Python', category: 'eCommerce', color: 'yellow' },
  { name: 'Automatically Source Higher Resolution Images', description: 'Automatically source higher-resolution images for eCommerce sites', link: 'https://github.com/searchsolved/search-solved-public-seo/tree/main/ecommerce/google-vision-find-higher-resolution-images', type: 'Python', category: 'eCommerce', color: 'pink' },
  { name: 'Add Branding in Bulk to PDF Files', description: 'Inject branding in .pdf files in bulk', link: 'https://github.com/searchsolved/search-solved-public-seo/tree/main/ecommerce/inject-branding-into-pdf-files', type: 'Python', category: 'eCommerce', color: 'teal' },
  { name: 'Find Keywords within Striking Distance', description: 'Automatically business branding in .pdf files in bulk', link: 'https://github.com/searchsolved/search-solved-public-seo/tree/main/keyword-research/striking-distance-creator', type: 'Google Colab', category: 'On-page', color: 'indigo' },
];

const typeFilters = ['Streamlit', 'Command Line', 'Python', 'Google Colab'];
const categoryFilters = ['Linking', 'Migration', 'On-page', 'Clustering', 'eCommerce'];

const indexableFilters = {
  type: {
    Streamlit: true,
    'Command Line': false,
    Python: true,
    'Google Colab': true
  },
  category: {
    Linking: true,
    Migration: true,
    'On-page': true,
    Clustering: true,
    eCommerce: true
  }
};

const getValidCombinations = () => {
  const validCombinations = new Set();
  projects.forEach(project => {
    validCombinations.add(`${project.type}|${project.category}`);
  });
  return validCombinations;
};

const validCombinations = getValidCombinations();

const getColorClasses = (color) => {
  const colorMap = {
    purple: 'bg-purple-50 hover:bg-purple-100 border-purple-200',
    blue: 'bg-blue-50 hover:bg-blue-100 border-blue-200',
    green: 'bg-green-50 hover:bg-green-100 border-green-200',
    orange: 'bg-orange-50 hover:bg-orange-100 border-orange-200',
    yellow: 'bg-yellow-50 hover:bg-yellow-100 border-yellow-200',
    pink: 'bg-pink-50 hover:bg-pink-100 border-pink-200',
    teal: 'bg-teal-50 hover:bg-teal-100 border-teal-200',
    gray: 'bg-gray-50 hover:bg-gray-100 border-gray-200',
    red: 'bg-red-50 hover:bg-red-100 border-red-200',
    indigo: 'bg-indigo-50 hover:bg-indigo-100 border-indigo-200',
    cyan: 'bg-cyan-50 hover:bg-cyan-100 border-cyan-200',
    emerald: 'bg-emerald-50 hover:bg-emerald-100 border-emerald-200',
    violet: 'bg-violet-50 hover:bg-violet-100 border-violet-200',
    fuchsia: 'bg-fuchsia-50 hover:bg-fuchsia-100 border-fuchsia-200',
    rose: 'bg-rose-50 hover:bg-rose-100 border-rose-200',
    amber: 'bg-amber-50 hover:bg-amber-100 border-amber-200',
    lime: 'bg-lime-50 hover:bg-lime-100 border-lime-200',
    sky: 'bg-sky-50 hover:bg-sky-100 border-sky-200',
    slate: 'bg-slate-50 hover:bg-slate-100 border-slate-200',
    stone: 'bg-stone-50 hover:bg-stone-100 border-stone-200',
  };
  return colorMap[color] || colorMap.gray;
};

export default function PersonalProfilePreview() {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [showAllProjects, setShowAllProjects] = useState(false);
  const [activeTypeFilter, setActiveTypeFilter] = useState('all');
  const [activeCategoryFilter, setActiveCategoryFilter] = useState('all');
  const [showFilters, setShowFilters] = useState(false);
  const [availableTypeFilters, setAvailableTypeFilters] = useState(typeFilters);
  const [availableCategoryFilters, setAvailableCategoryFilters] = useState(categoryFilters);
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

  const updateUrlHash = (type, category) => {
    let hash = '#!/';
    if (type !== 'all') hash += type;
    hash += '/';
    if (category !== 'all') hash += category;
    window.history.pushState(null, '', hash);
  };

  const updatePageTitle = (type, category) => {
    let title = 'Lee Foot - ';
    if (type === 'all' && category === 'all') {
      title += 'All Projects';
    } else if (type === 'all') {
      title += `All ${category} Projects`;
    } else if (category === 'all') {
      title += `All ${type} Projects`;
    } else {
      title += `${type} ${category} Projects`;
    }
    document.title = title;
  };

  const updateAvailableFilters = (type, category) => {
    if (type === 'all' && category === 'all') {
      setAvailableTypeFilters(typeFilters);
      setAvailableCategoryFilters(categoryFilters);
    } else if (type === 'all') {
      const availableTypes = new Set();
      projects.forEach(project => {
        if (project.category === category) {
          availableTypes.add(project.type);
        }
      });
      setAvailableTypeFilters([...availableTypes]);
    } else if (category === 'all') {
      const availableCategories = new Set();
      projects.forEach(project => {
        if (project.type === type) {
          availableCategories.add(project.category);
        }
      });
      setAvailableCategoryFilters([...availableCategories]);
    }
  };

  const setFilters = (type, category) => {
    let newType = type;
    let newCategory = category;

    if (type !== 'all' && category !== 'all') {
      if (!validCombinations.has(`${type}|${category}`)) {
        newType = 'all';
        newCategory = category;
      }
    }

    setActiveTypeFilter(newType);
    setActiveCategoryFilter(newCategory);
    updateAvailableFilters(newType, newCategory);
    updateUrlHash(newType, newCategory);
    updatePageTitle(newType, newCategory);
  };

  const clearFilters = () => {
    setFilters('all', 'all');
  };

  const handleTagClick = (e, filterType, value) => {
    e.preventDefault();
    e.stopPropagation();
    if (filterType === 'type') {
      setFilters(value, activeCategoryFilter);
    } else if (filterType === 'category') {
      setFilters(activeTypeFilter, value);
    }
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const executeRecaptcha = () => {
    return new Promise((resolve, reject) => {
      if (!recaptchaLoaded) {
        reject(new Error('reCAPTCHA has not loaded'));
        return;
      }
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6Ldtt0sqAAAAAPcj9KYEWcWcD8E0Xhsx7vWBK3ww', { action: 'submit' })
          .then(resolve)
          .catch((error) => {
            console.error('reCAPTCHA execution failed:', error);
            reject(error);
          });
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    try {
      const recaptchaToken = await executeRecaptcha();
      console.log('reCAPTCHA token obtained:', recaptchaToken);

      const response = await fetch('/.netlify/functions/subscribe', {
        method: 'POST',
        body: JSON.stringify({ ...formData, recaptchaToken }),
      });

      if (response.ok) {
        setSubmitStatus('success');
        setFormData({ name: '', email: '', message: '' });
      } else {
        const errorData = await response.json();
        console.error('Form submission failed:', errorData);
        setSubmitStatus('error');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus('error');
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    const hash = window.location.hash.slice(3).split('/');
    if (hash.length === 2) {
      const [type, category] = hash;
      setFilters(type || 'all', category || 'all');
    }
  }, []);

  useEffect(() => {
    const checkRecaptcha = () => {
      if (typeof window.grecaptcha !== 'undefined') {
        setRecaptchaLoaded(true);
        console.log('reCAPTCHA has loaded successfully');
      } else {
        console.error('reCAPTCHA has not loaded');
      }
    };

    const timer = setTimeout(checkRecaptcha, 1000);
    return () => clearTimeout(timer);
  }, []);

  const filteredProjects = projects.filter(project =>
    (activeTypeFilter === 'all' || project.type === activeTypeFilter) &&
    (activeCategoryFilter === 'all' || project.category === activeCategoryFilter)
  );

  const visibleProjects = showAllProjects ? filteredProjects : filteredProjects.slice(0, 4);

  return (
    <div className="bg-gray-100 text-gray-800 p-8 max-w-2xl mx-auto font-mono">
      <main>
        <section className="mb-6">
          <h2 className="text-4xl font-bold mb-2">Lee Foot</h2>
          <p className="text-sm mb-4 text-gray-600">
            TL;DR: Drum Award-winning eCommerce SEO for hire. 13+ years experience. Available for data analysis, automation, consulting & bespoke apps.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-bold mb-4">Skills & Expertise</h3>
          <div className="flex flex-wrap">
            {['SEO', 'eCommerce', 'Data Analysis', 'Python', 'React', 'Automation', 'Google Search Console', 'Technical SEO'].map(skill => (
              <span key={skill} className="bg-gray-200 text-gray-800 px-3 py-1 rounded-full text-sm mr-2 mb-2 inline-block">
                {skill}
              </span>
            ))}
          </div>
        </section>

        <button
          onClick={() => setIsContactFormOpen(!isContactFormOpen)}
          className="w-full bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700 transition-colors mb-6 font-bold"
        >
          {isContactFormOpen ? 'Let\'s make it happen!' : 'Hire Me for SEO Consulting'}
        </button>

        {isContactFormOpen && (
          <section className="mb-6">
            <h3 className="text-xl font-bold mb-4">Let's Discuss Your Project</h3>
            <div className="bg-blue-50 w-full p-4 rounded-md border border-blue-200">
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Your Name"
                  className="w-full p-2 mb-2 rounded border border-blue-200"
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Your Email"
                  className="w-full p-2 mb-2 rounded border border-blue-200"
                />
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  placeholder="Project Details"
                  className="w-full p-2 mb-2 rounded border border-blue-200"
                  rows={4}
                ></textarea>
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition-colors font-bold"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Sending...' : 'Send'}
                </button>
                {submitStatus === 'success' && (
                  <p className="mt-2 text-green-600">Your message has been sent successfully!</p>
                )}
                {submitStatus === 'error' && (
                  <p className="mt-2 text-red-600">There was an error sending your message. Please try again.</p>
                )}
                <p className="text-xs mt-2 text-gray-500">
                  This website is protected by reCAPTCHA, in accordance with Google's
                  <a href="https://policies.google.com/privacy" className="text-blue-500"> Privacy Policy</a> and
                  <a href="https://policies.google.com/terms" className="text-blue-500"> Terms of Service</a>.
                </p>
              </form>
            </div>
          </section>
        )}

        <section className="mb-6">
          <h3 className="text-xl font-bold mb-4">Connect with Me</h3>
          <div className="space-y-4">
            <a href="#" className="block bg-gray-200 w-full p-4 rounded-md border border-gray-300 flex items-start hover:bg-gray-300 transition-colors hover:bg-opacity-80">
              <span className="mr-3 mt-1 text-gray-800 text-xl">𝕏</span>
              <div className="flex-grow">
                <h4 className="font-bold">Follow @LeeFootSEO</h4>
                <p className="text-sm text-gray-600">Follow me for SEO tips and updates</p>
              </div>
            </a>
            <a href="#" className="block bg-blue-50 w-full p-4 rounded-md border border-blue-200 flex items-start hover:bg-blue-100 transition-colors hover:bg-opacity-80">
              <Linkedin size={20} className="mr-3 mt-1 text-blue-600" />
              <div className="flex-grow">
                <h4 className="font-bold">Follow me on LinkedIn</h4>
                <p className="text-sm text-gray-600">Connect with me professionally</p>
              </div>
            </a>
            <a href="https://github.com/searchsolved/search-solved-public-seo" target="_blank" rel="noopener noreferrer" className="block bg-gray-800 w-full p-4 rounded-md border border-gray-700 flex items-start hover:bg-gray-700 transition-colors">
              <Github size={20} className="mr-3 mt-1 text-white" />
              <div className="flex-grow">
                <h4 className="font-bold text-white">Check out my GitHub</h4>
                <p className="text-sm text-gray-300">Explore my open-source SEO projects</p>
              </div>
            </a>
          </div>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-bold mb-4">Featured Projects</h3>
          <div className="mb-4 flex items-center justify-between">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center bg-purple-50 text-purple-800 px-3 py-1 rounded-full text-sm hover:bg-purple-100 transition-colors"
            >
              <Filter size={16} className="mr-2" />
              {activeTypeFilter === 'all' && activeCategoryFilter === 'all' ? 'Filter Projects' : 'Filters Applied'}
            </button>
            {(activeTypeFilter !== 'all' || activeCategoryFilter !== 'all') && (
              <button
                onClick={clearFilters}
                className="text-sm text-purple-600 hover:underline"
              >
                Clear Filters
              </button>
            )}
          </div>
          {showFilters && (
            <div className="mb-4 bg-white p-4 rounded-md shadow-md">
              <h4 className="text-sm font-semibold mb-2">Project Type:</h4>
              <div className="flex flex-wrap gap-2 mb-4">
                <a
                  href="#!/all/all"
                  onClick={(e) => { e.preventDefault(); setFilters('all', 'all'); }}
                  className={`px-3 py-1 rounded-full text-sm ${activeTypeFilter === 'all' ? 'bg-purple-100 text-purple-800' : 'bg-purple-50 text-purple-800'}`}
                  rel="index,follow"
                >
                  All
                </a>
                {availableTypeFilters.map(filter => (
                  <a
                    key={filter}
                    href={`#!/${filter}/${activeCategoryFilter}`}
                    onClick={(e) => { e.preventDefault(); setFilters(filter, activeCategoryFilter); }}
                    className={`px-3 py-1 rounded-full text-sm ${activeTypeFilter === filter ? 'bg-purple-100 text-purple-800' : 'bg-purple-50 text-purple-800'}`}
                    rel={indexableFilters.type[filter] ? "index,follow" : "noindex,follow"}
                  >
                    {filter}
                  </a>
                ))}
              </div>
              <h4 className="text-sm font-semibold mb-2">Project Category:</h4>
              <div className="flex flex-wrap gap-2">
                <a
                  href="#!/all/all"
                  onClick={(e) => { e.preventDefault(); setFilters('all', 'all'); }}
                  className={`px-3 py-1 rounded-full text-sm ${activeCategoryFilter === 'all' ? 'bg-blue-100 text-blue-800' : 'bg-blue-50 text-blue-800'}`}
                  rel="index,follow"
                >
                  All
                </a>
                {availableCategoryFilters.map(filter => (
                  <a
                    key={filter}
                    href={`#!/${activeTypeFilter}/${filter}`}
                    onClick={(e) => { e.preventDefault(); setFilters(activeTypeFilter, filter); }}
                    className={`px-3 py-1 rounded-full text-sm ${activeCategoryFilter === filter ? 'bg-blue-100 text-blue-800' : 'bg-blue-50 text-blue-800'}`}
                    rel={indexableFilters.category[filter] ? "index,follow" : "noindex,follow"}
                  >
                    {filter}
                  </a>
                ))}
              </div>
            </div>
          )}
          <div className="space-y-4">
            {visibleProjects.map((project, index) => (
              <a key={index} href={project.link} target="_blank" rel="noopener noreferrer" className={`block ${getColorClasses(project.color)} w-full p-4 rounded-md border flex items-start transition-colors`}>
                <ExternalLink size={20} className="mr-3 mt-1 text-gray-600" />
                <div className="flex-grow">
                  <h4 className="font-bold">{project.name}</h4>
                  <p className="text-sm text-gray-600">{project.description}</p>
                  {project.type && project.category && (
                    <div className="mt-2 flex flex-wrap gap-2">
                      <button
                        onClick={(e) => handleTagClick(e, 'type', project.type)}
                        className="bg-purple-100 text-purple-800 px-2 py-0.5 rounded-full text-xs hover:bg-purple-200 transition-colors"
                      >
                        {project.type}
                      </button>
                      <button
                        onClick={(e) => handleTagClick(e, 'category', project.category)}
                        className="bg-blue-100 text-blue-800 px-2 py-0.5 rounded-full text-xs hover:bg-blue-200 transition-colors"
                      >
                        {project.category}
                      </button>
                    </div>
                  )}
                </div>
              </a>
            ))}
          </div>
          {filteredProjects.length > 4 && (
            <button
              onClick={() => setShowAllProjects(!showAllProjects)}
              className="mt-4 w-full bg-purple-50 text-purple-800 py-2 px-4 rounded-md hover:bg-purple-100 transition-colors flex items-center justify-center"
            >
              {showAllProjects ? (
                <>
                  <ChevronUp size={20} className="mr-2" />
                  Show Less
                </>
              ) : (
                <>
                  <ChevronDown size={20} className="mr-2" />
                  View More
                </>
              )}
            </button>
          )}
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-bold mb-4">Awards</h3>
          <div className="bg-yellow-50 w-full p-4 rounded-md border border-yellow-200 flex items-start">
            <Award size={24} className="mr-3 mt-1 text-yellow-600" />
            <div className="flex-grow">
              <h4 className="font-bold text-lg">Drum Search Awards</h4>
              <div className="grid grid-cols-2 gap-x-4 gap-y-1 mt-2 text-sm">
                <span className="text-gray-600">Year:</span>
                <span>2020</span>
                <span className="text-gray-600">Category:</span>
                <span>eCommerce</span>
                <span className="text-gray-600">Status:</span>
                <span className="text-yellow-600 font-semibold">Winner</span>
              </div>
              <p className="mt-2 text-sm italic">"The Doors of Disruption"</p>
            </div>
          </div>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-bold mb-4">Be the First To Know</h3>
          <div className="bg-red-50 p-4 rounded-md border border-red-200">
            <p className="text-sm mb-4 text-gray-600">Join Lee's list for sporadic emails about new apps, tools, and SEO strategies you should know about.</p>
            <form onSubmit={(e) => e.preventDefault()} className="flex items-center space-x-2">
              <div className="flex-grow bg-white rounded-md border border-red-200 flex items-center p-2">
                <Mail size={16} className="text-gray-400 mr-2" />
                <input
                  type="email"
                  placeholder="Your Email Address"
                  className="w-full bg-transparent text-sm focus:outline-none"
                  required
                />
              </div>
              <button
                type="submit"
                className="bg-red-600 text-white px-4 py-2 rounded-md hover:bg-red-700 transition-colors font-bold"
              >
                Subscribe
              </button>
            </form>
          </div>
        </section>
      </main>
    </div>
  );
}