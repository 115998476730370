import React from 'react';
import PersonalProfilePreview from './components/PersonalProfilePreview';

function App() {
  return (
    <div className="App">
      <PersonalProfilePreview />
    </div>
  );
}

export default App;